import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { styles } from './styles'
import { Modal, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import UploadFileIcon from '@material-ui/icons/CloudUpload'
import clipboard from '../../assets/images/clipboard.svg'
import CHRInput from '../input'
import { v4 as uuidv4 } from 'uuid'
import { Storage } from '@aws-amplify/storage'
import { CircularProgress } from '@material-ui/core'
import { ENDPOINTS, FONTS, ROUTES } from '../../constants'
import axios from '../../axios'
import { navigate } from 'gatsby'
import { useAppState } from '../../appState'
import { TrackingCase } from '../../../utils/trackingCases'
import { tracker } from '../../systemLogs'

const AddToShoppingBoardModal = ({ open, handleClose, link }: any) => {
  const { t } = useTranslation()
  const classes = styles()
  const [name, setName] = useState('')
  const [img, setImg] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const [isUploadingImg, setIsUploadingImg] = useState(false)
  const [checked, setChecked] = useState(false)
  const [error, setError] = useState('')
  const [appState, dispatch] = useAppState()

  const handleSaveProduct = async () => {
    if (!name) {
      return setError('Please insert product title')
    }
    if (!img && !checked) {
      return setError('Please upload an image for the product')
    }

    try {
      setIsSaving(true)

      tracker.track(
        TrackingCase.UserTracking,
        `POST ${ENDPOINTS.addItemToShoppingBoardForm}`,
        { location: 'AddToShoppingBoardModal', link, name, img }
      )
      const result = await axios.post(ENDPOINTS.addItemToShoppingBoardForm, {
        link,
        title: name,
        image: img,
      })
      const { data } = result.data

      if (data) {
        dispatch({
          type: 'UPDATE_AFTER_ADDING_TO_SHOPPING_BOARD',
          afterAddShoppingItem: true,
        })
        navigate(
          ROUTES.mainBoard.replace(':userName', appState.userProfile.userName)
        )
      }
    } catch (err) {
      setError(t('messages.somethingWentWrong'))
      setIsSaving(false)
    }
  }

  const onUploadImage = async e => {
    try {
      const files = e.target.files
      if (files) {
        setIsUploadingImg(true)
        const image = files[0]
        const imageName = `${uuidv4()}${image.name}`
        const result = await Storage.put(imageName, image, {
          level: 'public',
          contentType: 'image/gif, image/jpeg, image/png',
        })
        const imageUrl = `https://${process.env.GATSBY_S3_BUCKET_NAME}.s3.us-east-2.amazonaws.com/public/${result.key}`

        setImg(imageUrl)
        setIsUploadingImg(false)
      }
    } catch (err) {
      setError(t('messages.somethingWentWrong'))
    }
  }

  const onClose = () => {
    handleClose()
    setImg('')
    setName('')
    setIsSaving(false)
    setIsUploadingImg(false)
    setError('')
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={classes.paper}>
        <div className={classes.title}>
          <Typography
            variant="caption"
            style={{ fontFamily: FONTS.Graphik.GraphikLight }}
          >
            Product information not available via create a “share link” tool.
            Try browser extension OR manually add product name and photo.
          </Typography>
          <div style={{ cursor: 'pointer' }} onClick={onClose}>
            <CloseIcon fontSize="small" />
          </div>
        </div>
        <div className={classes.imgWrapper}>
          {isUploadingImg ? (
            <CircularProgress />
          ) : img ? (
            <img height={200} src={img} alt="uploading-image" />
          ) : (
            <>
              <label
                className={classes.imgWrapper}
                style={{
                  cursor: 'pointer',
                  fontFamily: FONTS.Graphik.GraphikLight,
                  paddingTop: 20,
                }}
              >
                <UploadFileIcon fontSize="large" />
                upload product image
                <input
                  type="file"
                  accept="image/jpeg, image/png"
                  onChange={onUploadImage}
                  style={{ visibility: 'hidden', height: 0 }}
                />
              </label>
              <p style={{ margin: 0 }}>or</p>
              <label
                className={classes.checkBox}
                style={{
                  cursor: 'pointer',
                  fontFamily: FONTS.Graphik.GraphikLight,
                }}
              >
                i don't have an image
                <input
                  checked={checked}
                  onChange={() => setChecked((c: boolean) => !c)}
                  type="checkbox"
                />
                <span className={classes.checkMark}></span>
              </label>
            </>
          )}
        </div>
        <CHRInput
          placeholder="product title"
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <button
          disabled={isSaving}
          className={classes.boardBtn}
          onClick={handleSaveProduct}
        >
          <img src={clipboard} alt={t('createLink.clipboard')} />
          <span className={classes.socialText}>
            {t('createLink.addToboard')}
          </span>
        </button>
        {error && (
          <Typography
            variant="subtitle1"
            component="p"
            className={classes.error}
          >
            {error}
          </Typography>
        )}
      </div>
    </Modal>
  )
}

export default AddToShoppingBoardModal
