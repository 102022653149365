import classNames from 'classnames'
import { TFunction } from 'i18next'
import * as React from 'react'
import { withTranslation } from 'react-i18next'
import { styles } from './styles'

interface BrandItemProps {
  name: string
  brandLogo: any
  cashBack?: string
  isActive?: boolean
  customStyle: any
  t: TFunction
  link: string
  isCardOnLanding?: boolean
  onClick: () => void
}

const BrandItemUser = ({
  name,
  brandLogo,
  link,
  cashBack,
  isActive,
  customStyle,
  isCardOnLanding,
  onClick,
  t,
  ...props
}: BrandItemProps) => {
  const classes = styles()
  const cashBackClasses = classNames({
    [classes.cashBackText]: true,
    [classes.cashBackTextActive]: isActive,
    [customStyle]: customStyle,
  })

  const cardBackground = isCardOnLanding ? classes.whiteCard : ''

  return (
    <div className={`${classes.card} ${cardBackground}`}>
      <a href={link} target="_blank" rel="noreferrer" onClick={onClick}>
        <img src={brandLogo} alt={name} className={classes.logo} />
        {cashBack && (
          <p className={cashBackClasses}>
            {cashBack} {t('home.cashBack')}
          </p>
        )}
      </a>
    </div>
  )
}

export default withTranslation()(BrandItemUser)
