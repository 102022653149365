import { TFunction } from 'i18next'
import React, { useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { styles } from './styles'

import { LocationProps } from '@reach/router'
import { navigate } from 'gatsby'
import { getBrands, getBrandsLogosURLSrc } from '../../../utils/brandsHelper'
import { sendExtensionMessage } from '../../../utils/extensionHelper'
import { trendingBrandsLogos } from '../../../utils/trendingBrandsLogos'
import { useAppState } from '../../appState'
import axios from '../../axios'
import CategoriesSection from '../../components/homeSections/categoriesSection'
import TopSection from '../../components/homeSections/topSection'
import PinExtensionReminderPopOut from '../../components/popOuts/pinExtensionReminderPopOut'
import { ENDPOINTS, ROUTES } from '../../constants'
import Layout from '../../layouts/defaultLayout'
import TopProducts from '../homeSections/topProductSection'
import WelcomePopOut from '../popOuts/welcomePopOut'
import AddExtensionPopup from '../popups/automaticPopOver/addExtensionPopUp'
import BrowserExtensionPopup from '../popups/automaticPopOver/browserExtensionPopup'
import SEO from '../seo'
import { getBlogs } from './blog-posts/index'

import { setItemToLocalStorage } from '../../../utils/setLocalStorage'
import ChirpyestBlog from '../../components/chirpyestBlog'
import ExtensionWarningModal from '../../components/extensionWarningModal'
import useNavigateToExtStore from '../../hooks/useNavigateToExtStore'
import BrandsListSection from '../homeSections/brandsListSection'
import TrendingProducts from '../trendingProducts'

interface MemberHomeProps {
  t: TFunction
  location: LocationProps
}

const MemberHome = ({ t, location }: MemberHomeProps) => {
  const [appState] = useAppState()
  const classes = styles()
  const [trendingBrands, setTrendingBrands] = useState([])
  const [blogs, setBlogs] = useState([])
  const [
    isExtWarningModalOpen,
    setExtWarningModalOpen,
    navigateToStore,
  ] = useNavigateToExtStore()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [
    isBrowserExtensionPopUpOpen,
    setIsBrowserExtensionPopUpOpen,
  ] = useState(false)
  const [isAddExtensionPopUpOpen, setIsAddExtensionPopUpOpen] = useState(false)
  const [isPinExtensionModal, setPinExtensionModalOpen] = useState(false)
  const [extensionInstalled, setExtensionInstalled] = useState(true)
  let extensionTimeoutRef = useMemo(
    () =>
      setTimeout(() => {
        setExtensionInstalled(false)
      }, 1 * 1000),
    []
  )

  useEffect(() => {
    window.addEventListener('chirpExtMessage', onSendExtMessage)
    sendExtensionMessage('ping')
    return window.removeEventListener('chirpExtMessage', onSendExtMessage)
  }, [])

  useEffect(() => {
    if (window.localStorage.getItem('signUp')) {
      if (!window.localStorage.getItem('extensionDownloaded')) {
        setIsModalOpen(true)
      }
      window.localStorage.removeItem('signUp')
    } else if (location?.state && location?.state?.showPopUp) {
      setPinExtensionModalOpen(true)
    }
    const getUserEarnings = () =>
      axios.get(ENDPOINTS.user.replace(':id', appState.userId))
    const initialize = async () => {
      const [brands, blogs, userEarnings] = await Promise.all([
        getBrands().catch(() => null),
        getBlogs(),
        getUserEarnings().catch(() => null),
      ])

      const filteredBrands = trendingBrands
        ? brands.data.data.filter((item: any) => item.isTrending)
        : []
      const filteredTrendingBrands = getBrandsLogosURLSrc(
        filteredBrands,
        trendingBrandsLogos
      )
      const limitedBrands = filteredTrendingBrands.slice(0, 16)
      setTrendingBrands(limitedBrands)

      setBlogs(blogs)

      let pageIndex = Math.floor(Math.random() * 4)
      if (
        !window.localStorage.getItem('extensionDownloaded') &&
        pageIndex === 1
      ) {
        setIsAddExtensionPopUpOpen(true)
      }

      setItemToLocalStorage('enterPage', 'true')
    }
    initialize()
  }, [appState.userId])

  const onSendExtMessage = data => {
    if (data.detail === 'pong') {
      setExtensionInstalled(true)
      clearTimeout(extensionTimeoutRef)
    }
  }

  const handleClose = () => {
    setIsModalOpen(false)
    if (window.localStorage.getItem('extensionDownloaded')) {
      setIsBrowserExtensionPopUpOpen(true)
    }
    if (location?.state && location?.state?.showPopUp) {
      setTimeout(() => {
        setPinExtensionModalOpen(true)
      }, 5000)
    }
  }

  // when users attempt to auth with instagram it will redirect them to this page
  // we make a check if a user is actually visiting the page or
  // being redirected so that we get them back to their shopping board with auth code
  const [_, redirectCode] =
    window.location.search.match(/.*code=([^&|\n|\t\s]+)/) || []

  if (redirectCode) {
    return navigate(`/s/${appState.username}?redirectCode=${redirectCode}`)
  }

  if (window.localStorage.getItem('redirectToBoardPage')) {
    window.localStorage.removeItem('redirectToBoardPage')
    return navigate(ROUTES.mainBoard.replace(':userName', appState.username))
  }

  return (
    <Layout>
      <SEO title={t('memberHome.metaTitle')} />
      {/* title and tabs */}
      {/* Top Section */}
      <TopSection
        isExtentionDownloaded={extensionInstalled}
        addExtention={navigateToStore}
      />
      {/* Top Products Section */}
      <TopProducts />
      {/* Categories Section */}
      <CategoriesSection
        isExtentionDownloaded={extensionInstalled}
        addExtention={navigateToStore}
      />
      {/* Branding List */}
      <BrandsListSection brands={trendingBrands} />
      {/* Blog section */}
      <ChirpyestBlog blogs={blogs} />
      {/* Trending list */}
      <TrendingProducts />

      <WelcomePopOut
        open={isModalOpen}
        handleClose={handleClose}
        onNavigateToExtStore={navigateToStore}
      />
      <BrowserExtensionPopup
        open={isBrowserExtensionPopUpOpen}
        handleClose={() => setIsBrowserExtensionPopUpOpen(false)}
      />
      <AddExtensionPopup
        open={isAddExtensionPopUpOpen}
        handleClose={() => setIsAddExtensionPopUpOpen(false)}
      />
      <PinExtensionReminderPopOut
        open={isPinExtensionModal}
        handleClose={() => setPinExtensionModalOpen(false)}
      />
      <ExtensionWarningModal
        isOpen={isExtWarningModalOpen}
        onClose={() => setExtWarningModalOpen(false)}
      />
      {/* Our extension section */}
      {/* {!extensionInstalled ? (
        <MiddleSection
          handleArrowClick={navigateToStore}
          image={earnCashBack2}
          rightChildren={
            <div>
              <Typography variant="h3">{t('home.earnCash')}</Typography>
              <Typography variant="h2">{t('home.addOurExtension')}</Typography>
              <Typography
                variant="subtitle1"
                component="p"
                className={classes.middleSectionContent}
              >
                {t('home.earnCashText')}
              </Typography>
            </div>
          }
        />
      ) : (
        <MiddleSection
          handleArrowClick={navigateToStore}
          image={extensionInstalledImg}
          rightChildren={
            <div>
              <Typography variant="h3">{t('home.earnCash')}</Typography>
              <Typography variant="h2">
                {t('memberHome.useOurExtension')}
              </Typography>
              <Typography
                variant="subtitle1"
                component="p"
                className={classes.middleSectionContent}
              >
                {t('memberHome.extensionInstalledText')}
              </Typography>
            </div>
          }
        />
      )} */}

      {/* chirpyest pro section */}
      {/* <MiddleSection
        handleArrowClick={() => navigate(ROUTES.chirpyestPro)}
        image={chirpyestProImg}
        buttonLabel={t('memberHome.learnMore')}
        rightChildren={
          <div>
            <Typography variant="h3">{t('memberHome.proTitle')}</Typography>
            <Typography variant="h2">{t('memberHome.proSubtitle')}</Typography>
            <Typography
              variant="subtitle1"
              component="p"
              className={classes.middleSectionContent}
            >
              {t('memberHome.proText')}
            </Typography>
          </div>
        }
      /> */}

      {/* <div className={classes.trendingContainer}>
        <Typography
          variant="h1"
          className={classes.mobileEdgeSpace + ' ' + classes.trendingHeading}
        >
          {t('memberHome.brandsList')}
        </Typography>
        <Typography variant="subtitle1" className={classes.trendingSubtitle}>
          {t('memberHome.brandsListSubtitle')}
        </Typography>
      </div>
      <div className={classes.mobileBackground}>
        <section className={classes.brandsListSection}>
          <div className={classes.brandsListContainer}>
            <BrandsList>
              {trendingBrands.map((brand: any, index: number) => {
                return (
                  <BrandItem
                    brandLogo={brand.src}
                    name={brand.brandName}
                    cashBack={brand.commission}
                    link={generateTrackingLink(
                      brand,
                      {
                        userId: appState.userId,
                        userName: appState.username,
                      },
                      '',
                      '',
                      Boolean(appState.userId)
                    )}
                    key={index}
                    isCardOnLanding
                  />
                )
              })}
            </BrandsList>
          </div>
        </section>
      </div> */}

      {/* feed section */}
      {/* <div className={classes.sectionContainer}>
        <div className={classes.feedTitle}>
          <Typography variant="h1" className={classes.mobileEdgeSpace}>
            {t('shared.chirpyest')}
          </Typography>
          <img
            src={heart}
            alt={t('memberHome.heart')}
            className={classes.feedTitleHeart}
          />
        </div>
        <Grid container justifyContent="center">
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <a href="https://www.instagram.com/howtojcrew/" target="_blank">
              <img
                src={feedImg1}
                alt={t('imageAlts.placeholder')}
                className={classes.feedImg}
              />
            </a>
            <Typography variant="h2" className={classes.mobileEdgeSpace}>
              @howtojcrew
            </Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <a href="https://www.instagram.com/adelineania/" target="_blank">
              <img
                src={feedImg2}
                alt={t('imageAlts.placeholder')}
                className={classes.feedImg}
              />
            </a>
            <Typography variant="h2" className={classes.mobileEdgeSpace}>
              @adelineania
            </Typography>
          </Grid>
        </Grid>
      </div> */}
    </Layout>
  )
}

export default withTranslation()(MemberHome)
