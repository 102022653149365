import { baseURL, ENDPOINTS } from '../../../constants'
import axios from '../../../axios'

export const getBlogs = async (): Promise<any[]> => {
  let blogs: any[] = []
  try {
    const rssResponse = await axios.get(`${baseURL}${ENDPOINTS.blogs}?top=4`)
    const rss = rssResponse.data.data
    const blogsCount = 4
    blogs = rss.slice(0, blogsCount).map((item: any) => ({
      title: item.title,
      image: item.image,
      link: item.link,
    }))

    return blogs
  } catch (err) {
    return []
  }
}
