import { Typography, Grid, Box } from '@material-ui/core'
import { TFunction } from 'i18next'
import React, { useRef, useState } from 'react'
import { withTranslation } from 'react-i18next'
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
} from 'react-share'
import { checkIfSubmittable } from '../../../utils/validation'
import emailIcon from '../../assets/images/dark-email.svg'
import facebookIcon from '../../assets/images/facebook.svg'
import twiterIcon from '../../assets/images/twitter.svg'
import smsIcon from '../../assets/images/sms.svg'
import clipboard from '../../assets/images/clipboard.svg'
import pinterest from '../../assets/images/pinterest.svg'
import axios from '../../axios'
import { baseURL, COLORS, ENDPOINTS, ROUTES } from '../../constants'
import Layout from '../../layouts/defaultLayout'
import CHRButton from '../button'
import { useAppState } from '../../appState'
import CHRContentContainer from '../contentContainer'
import CHRInput from '../input'
import CHRSectionContainer from '../sectionContainer'
import SEO from '../seo'
import { styles } from './styles'
import { navigate } from 'gatsby'
import { CircularProgress } from '@material-ui/core'
import { isIOS, isMacOs } from 'react-device-detect'
import { useLocation } from '@reach/router'
import { parse } from 'query-string'
import AddToShoppingBoardModal from '../addToShoppingBoardModal'
import { TrackingCase } from '../../../utils/trackingCases'
import { tracker } from '../../systemLogs'

const SocialShareLink = ({
  children,
  onInitial,
  onSuccess,
  link,
  product,
}: any) => {
  const ref = useRef(null)
  const classes = styles()

  const handleParseProduct = async () => {
    try {
      onInitial()
      const res = await axios.post(ENDPOINTS.parseProduct, {
        link,
      })
      onSuccess({
        image: res.data.data.imageUrl,
      })
      setTimeout(() => ref.current.click(), 0)
    } catch (err) {
      onSuccess({
        image: '/src/favicon-512x512.png',
      })
      setTimeout(() => ref.current.click(), 0)
    }
  }

  return (
    <button
      onClick={e => {
        // for some reason, calling click on pintreset button will bubble and trigger click on parent again
        // pinterest button has aria label of 'pinterest' this check will avoids click coming from it
        if (e.target.ariaLabel === 'pinterest') return
        handleParseProduct()
      }}
      style={{ background: 'transparent', cursor: 'pointer' }}
    >
      {product.loading && (
        <div className={classes.loading}>
          <CircularProgress style={{ color: COLORS.black }} size={40} />
        </div>
      )}
      {children(ref, product)}
    </button>
  )
}

interface CreateLinkPageProps {
  t: TFunction
}

const CreateLinkPage = ({ t }: CreateLinkPageProps) => {
  const { search } = useLocation()
  const { shortLink, originalLink, commission } = parse(search)

  const classes = styles()
  const [appState, dispatch] = useAppState()
  const [loading, setLoading] = useState(false)
  const [linkToConvert, setLinkToConvert] = useState(originalLink || '')
  const [shortenedLink, setShortenedLink] = useState({
    value: shortLink || '',
    commission: commission || '',
    isLoading: false,
    trackingLink: '',
  })
  const [currentMessage, setCurrentMessage] = useState({
    text: '',
    error: false,
  })
  const [product, setProduct] = useState({})

  const currentMessageStyle = currentMessage.error
    ? classes.errorMsg
    : classes.infoMsg
  const textAreaRef = useRef(null)
  const [isAddProductOpen, setIsAddProductOpen] = useState(false)

  const handleConvertAnotherLink = () => {
    setCurrentMessage({ text: '' })
    setLinkToConvert('')
    setShortenedLink({ value: '', trackingLink: '' })
    window.history.pushState('', '', `?`)
  }

  const copyToClipboard = (event: any) => {
    if (textAreaRef && textAreaRef?.current !== null) {
      textAreaRef?.current?.select()
      document.execCommand('copy')
      event?.target?.focus()
      const successMessage = t('messages.copiedToClipboard')
      setCurrentMessage({ text: successMessage, error: false })
    } else {
      const failMessage = t('messages.couldNotSelectText')
      setCurrentMessage({ text: failMessage, error: false })
    }
  }

  const convertURLToStandardFormat = (url: any) => {
    if (url.includes('https://') || url.includes('http://')) {
      return url
    } else {
      return 'https://' + url
    }
  }
  const handleCreateLink = (event: any) => {
    setCurrentMessage({ text: '' })
    if (linkToConvert && linkToConvert.length > 0) {
      setShortenedLink({ isLoading: true })
      const targetLink = convertURLToStandardFormat(linkToConvert)
      try {
        let refinedURL
        let productURL = new URL(targetLink)
        if (
          productURL.host === 'www.containerstore.com' &&
          productURL.search.includes('q=')
        ) {
          productURL.search.split('&').forEach(param => {
            if (param.includes('productId')) {
              refinedURL = productURL.origin + productURL.pathname + '?' + param
            }
          })
        } else {
          refinedURL = targetLink
          refinedURL = linkToConvert
        }
        ;(async () => {
          const shortenedValue = await axios.post(
            `${baseURL}/${ENDPOINTS.shortenLink}`,
            { url: refinedURL }
          )
          const { data } = shortenedValue.data
          if (!data.isShortLinksEnabled) {
            setCurrentMessage({
              text: t('messages.DisabledCreateShortLinkAndAddToShoppingBoard'),
              error: true,
            })
          }
          if (data && data.shortUrl?.length > 0) {
            setShortenedLink({
              trackingLink: data.trackingLink,
              value: data.shortUrl,
              commission: data.commission,
            })
            window.history.pushState(
              '',
              '',
              `?shortLink=${data.shortUrl}&originalLink=${refinedURL}&commission=${data.commission}`
            )
          }
          setShortenedLink(prevState => ({ ...prevState, isLoading: false }))
        })().catch(err => {
          if (
            err?.response?.data?.message ===
            'Sorry, this link is not in our network'
          ) {
            setCurrentMessage({
              text: t('messages.unfoundURL'),
              error: true,
            })
          } else if (
            err?.response?.data?.message === 'url must be a valid URL'
          ) {
            setCurrentMessage({
              text: t('messages.invalidURL'),
              error: true,
            })
          }

          setShortenedLink({ value: '', trackingLink: '' })
        })
      } catch (error) {
        setShortenedLink({ isLoading: false })
        setCurrentMessage({ text: t('messages.pleaseTryAgain'), error: true })
      }
    } else {
      setCurrentMessage({ text: t('messages.insertLink'), error: true })
    }
  }

  const handleSMSButtonClick = event => {
    if (isIOS || isMacOs) {
      window.open(
        `sms:?&body=${t('createLink.smsMessage') + shortenedLink.value}`,
        '_self'
      )
    } else {
      window.open(
        `sms:?body=${t('createLink.smsMessage') + shortenedLink.value}`,
        '_self'
      )
    }
  }

  const handleAddToShoppingBoard = event => {
    setLoading(true)
    setCurrentMessage({ text: '' })
    if (linkToConvert && linkToConvert.length > 0) {
      setLoading(true)

      try {
        tracker.track(
          TrackingCase.UserTracking,
          `POST ${ENDPOINTS.addItemToShoppingBoard}`,
          { location: 'CreateLinkPage', linkToConvert }
        )
        ;(async () => {
          const result = await axios.post(ENDPOINTS.addItemToShoppingBoard, {
            link: linkToConvert,
          })
          const { data } = result.data
          if (data) {
            // Back to shopping board
            dispatch({
              type: 'UPDATE_AFTER_ADDING_TO_SHOPPING_BOARD',
              afterAddShoppingItem: true,
            })
            navigate(
              ROUTES.mainBoard.replace(
                ':userName',
                appState.userProfile.userName
              )
            )
          }
          setLoading(false)
        })().catch(err => {
          setLoading(false)
          const resErr = err.response.data.message
          if (err.response && err.response.data) {
            if ('Shopping Board not found!' === resErr) {
              return navigate(
                ROUTES.mainBoard.replace(
                  ':userName',
                  appState.userProfile.userName
                )
              )
            }

            if ('product not found!' === resErr) {
              return setIsAddProductOpen(true)
            }

            const errMsg =
              'Brand not found!' === resErr
                ? t('messages.brandNotFound')
                : resErr === 'Shopping Board Disabled!'
                ? t('messages.DisabledCreateShortLinkAndAddToShoppingBoard')
                : resErr

            setCurrentMessage({
              text: errMsg,
              error: true,
            })
          }
        })
      } catch (error) {
        setLoading(false)
        setCurrentMessage({ text: t('messages.pleaseTryAgain'), error: true })
      }
    } else {
      setLoading(false)
      setCurrentMessage({ text: t('messages.insertLink'), error: true })
    }
  }

  return (
    <Layout>
      <SEO title={t('createLink.metaTitle')} />
      <CHRSectionContainer>
        <CHRContentContainer>
          <AddToShoppingBoardModal
            open={isAddProductOpen}
            handleClose={() => setIsAddProductOpen(false)}
            link={linkToConvert}
          />
          {loading && (
            <div className={classes.loading}>
              <CircularProgress style={{ color: COLORS.black }} size={40} />
            </div>
          )}
          <div className={classes.container}>
            <div className={classes.textContent}>
              <Typography variant="h1">
                {shortenedLink.value
                  ? t('createLink.convertedLinkTitle')
                  : t('createLink.convertLinkTitle')}
              </Typography>
              <Typography
                variant="subtitle1"
                component="p"
                className={classes.subtitle}
              >
                {shortenedLink.value
                  ? shortenedLink.commission
                    ? t('createLink.convertedsubtitle', {
                        commission: shortenedLink.commission,
                      })
                    : t('createLink.convertedsubtitle2')
                  : t('createLink.pasteLinkSubtitle')}
              </Typography>
              {currentMessage.text && (
                <Typography
                  variant="subtitle1"
                  component="p"
                  className={`${classes.alertText} ${currentMessageStyle}`}
                >
                  {currentMessage.text}
                </Typography>
              )}
            </div>
            <div
              className={`${classes.inputContainer} ${classes.pasteLinkInput}`}
            >
              <CHRInput
                placeholder={t('createLink.pasteLink')}
                value={linkToConvert}
                onChange={(event: any) => setLinkToConvert(event.target.value)}
                onKeyPress={event => {
                  shortenedLink.value === '' &&
                    checkIfSubmittable(event, handleCreateLink)
                }}
                disabled={shortenedLink.value ? true : false}
                type="url"
              />
            </div>
            {shortenedLink.value && (
              <>
                <Box mt={2.5}>
                  <div
                    className={`${classes.inputContainer} ${classes.convertedLink}`}
                  >
                    <CHRInput
                      placeholder=""
                      value={shortenedLink.value}
                      onChange={() => shortenedLink.value}
                      elementRef={textAreaRef}
                    />
                    {document.queryCommandSupported('copy') && (
                      <CHRButton
                        label={t('createLink.copyLink')}
                        onClick={copyToClipboard}
                      />
                    )}
                  </div>
                </Box>
                <Box mt={2.5}>
                  <div className={classes.inputContainer}>
                    <a
                      className={classes.shopLinkBtn}
                      href={`${shortenedLink.value}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('createLink.shopLink')}
                    </a>
                  </div>
                </Box>
                <Box mt={2.5}>
                  <div className={classes.inputContainer}>
                    <button
                      label={t('createLink.convertAnotherLink')}
                      onClick={handleConvertAnotherLink}
                      className={classes.convertAnotherLinkBtn}
                    >
                      {t('createLink.convertAnotherLink')}
                    </button>
                  </div>
                </Box>
              </>
            )}
            {!shortenedLink.value && (
              <Box className={classes.createLinkBtns} mt={2.5}>
                <Grid
                  alignItems="center"
                  container
                  justifyContent="space-between"
                  spacing={3}
                >
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <CHRButton
                      label={t('createLink.createShareLink')}
                      onClick={handleCreateLink}
                      isSubmitting={shortenedLink.isLoading}
                      disabled={shortenedLink.isLoading}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <button
                      className={classes.boardBtn}
                      onClick={handleAddToShoppingBoard}
                      disabled={shortenedLink.isShoppingBoardEnabled}
                    >
                      <img src={clipboard} alt={t('createLink.clipboard')} />
                      <span className={classes.socialText}>
                        {t('createLink.addToboard')}
                      </span>
                    </button>
                  </Grid>
                </Grid>
              </Box>
            )}
            {/* ----------------SOCIAL SHARE BUTTONS----------------------- */}
            {shortenedLink.value && (
              <Box className={classes.createLinkBtns} mt={2.5}>
                <Grid
                  alignItems="center"
                  container
                  justifyContent="space-between"
                  spacing={3}
                >
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <button
                      className={classes.boardBtn}
                      onClick={handleAddToShoppingBoard}
                      disabled={shortenedLink.isShoppingBoardEnabled}
                    >
                      <img src={clipboard} alt={t('createLink.clipboard')} />
                      <span className={classes.socialText}>
                        {t('createLink.addToboard')}
                      </span>
                    </button>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <div
                      className={`${classes.inputContainer} ${classes.socialButton}`}
                    >
                      <EmailShareButton
                        subject={t('createLink.emailSubject')}
                        body={t('createLink.emailBody')}
                        url={shortenedLink.value}
                        disabled={shortenedLink.isShortLinksEnabled}
                      >
                        <img
                          src={emailIcon}
                          alt={t('shared.email')}
                          className={classes.socialIcon}
                        />
                        <span className={classes.socialText}>{`${t(
                          'shared.shareOn'
                        )} ${t('shared.email')}`}</span>
                      </EmailShareButton>
                    </div>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <div
                      className={`${classes.inputContainer} ${classes.socialButton}`}
                    >
                      <button
                        className={classes.smsButton}
                        onClick={handleSMSButtonClick}
                        disabled={shortenedLink.isShortLinksEnabled}
                      >
                        <img
                          src={smsIcon}
                          alt={t('shared.sms')}
                          className={classes.socialIcon}
                        />
                        <span className={classes.socialText}>{`${t(
                          'shared.shareOn'
                        )} ${t('shared.sms')}`}</span>
                      </button>
                    </div>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <div
                      className={`${classes.inputContainer} ${classes.socialButton}`}
                    >
                      <FacebookShareButton
                        url={shortenedLink.value}
                        quote={t('createLink.socialMessage')}
                        disabled={shortenedLink.isShortLinksEnabled}
                      >
                        <img
                          src={facebookIcon}
                          alt={t('imageAlts.facebook')}
                          className={classes.socialIcon}
                        />
                        <span className={classes.socialText}>{`${t(
                          'shared.shareOn'
                        )} ${t('imageAlts.facebook')}`}</span>
                      </FacebookShareButton>
                    </div>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <div
                      className={`${classes.inputContainer} ${classes.socialButton}`}
                    >
                      <TwitterShareButton
                        url={shortenedLink.value}
                        title={t('createLink.socialMessage')}
                        disabled={shortenedLink.isShortLinksEnabled}
                      >
                        <img
                          src={twiterIcon}
                          alt={t('imageAlts.twitter')}
                          className={classes.socialIcon}
                        />
                        <span className={classes.socialText}>{`${t(
                          'shared.shareOn'
                        )} ${t('imageAlts.twitter')}`}</span>
                      </TwitterShareButton>
                    </div>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <div
                      className={`${classes.inputContainer} ${classes.socialButton}`}
                    >
                      <SocialShareLink
                        product={product}
                        link={linkToConvert}
                        onInitial={() =>
                          setProduct({
                            image: '/src/favicon-512x512.png',
                            loading: true,
                          })
                        }
                        onSuccess={data =>
                          setProduct({
                            image: data.image,
                            loading: false,
                          })
                        }
                      >
                        {(ref, parsedProduct) => (
                          <>
                            <PinterestShareButton
                              ref={ref}
                              url={shortenedLink.trackingLink}
                              disabled={shortenedLink.isShortLinksEnabled}
                              description={t('createLink.socialMessage')}
                              media={parsedProduct.image}
                              style={{
                                display: 'none',
                              }}
                            >
                              <img
                                src={pinterest}
                                alt={t('imageAlts.pinterest')}
                                className={classes.socialIcon}
                              />
                              <span className={classes.socialText}>
                                {t('createLink.pinterest')}
                              </span>
                            </PinterestShareButton>
                            <img
                              src={pinterest}
                              alt={t('imageAlts.pinterest')}
                              className={classes.socialIcon}
                            />
                            <span className={classes.socialText}>
                              {t('createLink.pinterest')}
                            </span>
                          </>
                        )}
                      </SocialShareLink>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            )}
          </div>
        </CHRContentContainer>
      </CHRSectionContainer>
    </Layout>
  )
}
export default withTranslation()(CreateLinkPage)
